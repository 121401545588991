import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders  } from '@angular/common/http';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

// utility
import { loggedInUser } from '../helpers/utils';

import { getAllUsers } from './../helpers/utils';

// types
import { PublicUserTO, User } from '../models/auth.models';
import { Environment } from 'src/libs/env/environment';

let users: User[] = getAllUsers();

export interface RefreshEvent {
  next: (credential: User) => void;
  error: (error: any) => void;
  credential?: User | null;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private intervalId: any;

  private refreshQueue: Subscriber<User>[];

  public readonly onInit: ReplaySubject<void>;

  public readonly onRefresh: EventEmitter<RefreshEvent>;

  public readonly onForbidden: EventEmitter<void>;

  public readonly onInvalidate: EventEmitter<void>;

  public readonly onUnauthorized: EventEmitter<void>;

  user: User | null = null;

  constructor(private http: HttpClient, private environment: Environment) {
    this.refreshQueue = [];
    this.onRefresh = new EventEmitter<RefreshEvent>();

    this.onInit = new ReplaySubject<void>(1);
    this.onForbidden = new EventEmitter<void>();
    this.onInvalidate = new EventEmitter<void>();
    this.onUnauthorized = new EventEmitter<void>();
  }

  public init(): void {
    let data = localStorage.getItem('currentUser');

    if (data) {
      this.user = JSON.parse(data);
    }

    if (this.user) {
      this.emitRefresh().subscribe(() => this.onInit.next());
    } else {
      this.onInit.next();
    }
  }

  /**
   * Returns the current user
   */
  public currentUser(): User | null {
    if (!this.user) {
      this.user = loggedInUser();
    }
    return this.user;
  }

  

  /**
   * Performs the login auth
   * @param login login of user
   * @param password password of user
   */
  login(login: string, password: string): Observable<User> {
    return this.http
      .post<User>(`${this.environment.api.apiURL}/api/v1/auth/auth/login`, {
        login,
        password,
        grantType: 'password',
        modulesId: [
          '12472e7c-4abc-11e9-a4d1-0242ac110003', //Auth
          '07498bb5-d7b4-4a4d-82f8-5eb9e2497478', //Socio
          'e380f282-0015-40a9-aa06-e20b1f291d53', //Adm
        ],
      })
      .pipe(
        map((credential) => {
          if (credential && credential.accessToken) {
            this.setCredential(credential);
          }
          return credential;
        })
      );
  }


  changePassword(passwordTO: any, token: string): Observable<any> {
    let params: HttpParams = new HttpParams().append('requestToken', token);
    return this.http.put(`${this.environment.api.apiURL}/api/v1/auth/auth/user-request/password`, passwordTO, { params });

  }

  recoverPasswordRequest(email: string): Observable<any> {
    return this.http.post(`${this.environment.api.apiURL}/api/v1/auth/auth/user-request/recover-password-clube`, { email });
  } 

  
  findUserByCpf(cpf: string): Observable<any> {
    return this.http.get(`${this.environment.api.apiURL}/api/v1/auth/users/user-by-cpf/${cpf}`);

  }

  listCheckBoxStatuses(): Observable<any> {
    return this.http.get(`${this.environment.api.apiURL}/api/v1/auth/users-statuses`);

  }
  
  changeUserStatus(cpf: string, newStatus: string): Observable<any> {
    return this.http.put(`${this.environment.api.apiURL}/api/v1/auth/users/changeStatusByCpf/${cpf}/${newStatus}`,{
      
    });

  }

  refresh(refreshToken: string): Observable<User> {
    return this.http
      .post<User>(`${this.environment.api.apiURL}/api/v1/auth/auth/refresh`, {
        modulesId: [
          '12472e7c-4abc-11e9-a4d1-0242ac110003', //Auth
          '07498bb5-d7b4-4a4d-82f8-5eb9e2497478', //Socio
          'e380f282-0015-40a9-aa06-e20b1f291d53', //Adm
        ],
        refreshToken: refreshToken,
      })
      .pipe(
        map((credential) => {
          return credential;
        })
      );
  }

  recoverPassword(email: string) {
    const params = new HttpParams().append('email', email);
    return this.http.get(
      `${this.environment.api.apiURL}/api/v1/auth/auth/user-request/recover-password`,
      { params }
    );
  }

  public emitRefresh(): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      if (this.refreshQueue.length === 0) {
        this.onRefresh.emit({
          credential: this.user,
          next: (credential: User) => {
            this.setCredential(credential, false);
            this.refreshQueue.forEach((data: Subscriber<User>) =>
              data.next(credential)
            );
            this.refreshQueue = [];
          },
          error: (error: any) => {
            this.emitUnauthorized();
            this.refreshQueue.forEach((data: Subscriber<User>) =>
              data.error(error)
            );
            this.refreshQueue = [];
          },
        });
      }

      this.refreshQueue.push(subscriber);
    });
  }

  public setCredential(credential: User, emitEvent: boolean = true) {
    if (credential && credential.accessToken) {
      this.user = credential;
      localStorage.setItem('currentUser', JSON.stringify(credential));

      if (credential.accessExpiresIn) {
        const expiresIn = (credential.accessExpiresIn - 60) * 1000;
        //const expiresIn = 10000;

        if (this.intervalId) {
          clearInterval(this.intervalId);
        }

        this.intervalId = setInterval(() => {
          clearInterval(this.intervalId);
          this.emitRefresh().subscribe(() => this.onInit.next());
        }, expiresIn);
      }

      if (emitEvent) {
        this.onInit.next();
      }
    }
  }

  public getPublicUserById(id: string): Observable<PublicUserTO> {
    return this.http.get<PublicUserTO>(`${this.environment.api.apiURL}/public/api/v1/auth/users/${id}`);
  }

  public getListPublicUser(ids: string[]): Observable<PublicUserTO[]> {
    return this.http.post<PublicUserTO[]>(`${this.environment.api.apiURL}/public/api/v1/auth/users/findByIds`, ids);
  }

changePasswordLoggedIn(newPassword: string, passwordConfirmation: string, authToken: string): Observable<void> {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'application/json'
  });

  const body = {
    newPassword,
    passwordConfirmation
  };

  return this.http.put<void>(`${this.environment.api.apiURL}/api/v1/auth/users/password`, body, { headers });
}

  /**
   * Performs the signup auth
   * @param name name of user
   * @param email email of user
   * @param password password of user
   */
  signup(name: string, email: string, password: string): Observable<User> {
    return this.http
      .post<User>(`${this.environment.api.apiURL}/api/signup`, {
        name,
        email,
        password,
      })
      .pipe(map((user) => user));
  }

  /**
   * Logout the user
   */
  logout(): void {
    // remove user from session storage to log user out
    localStorage.removeItem('currentUser');
    this.user = null;
  }

  public invalidate(): void {
    this.logout();
    this.onInvalidate.emit();
  }

  public emitUnauthorized(): void {
    this.onUnauthorized.emit();
  }

  public emitForbidden(): void {
    this.onForbidden.emit();
  }

  public get isIni(): boolean {
    return !!this.intervalId;
  }

  public get credential(): Partial<User> {
    return this.user || {};
  }
}
