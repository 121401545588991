import { EnvironmentScheme } from './scheme';

export const environment: EnvironmentScheme = {
  production: false,
  api: {
    webURL: 'http://localhost:4200',
    apiURL: '__API_GATEWAY__',
    admApiURL: '__API_GATEWAY__',
    socioApiURL: '__API_GATEWAY__',
    urlS3: 'https://efui-dev-api-docs.s3.sa-east-1.amazonaws.com'
  },
  tenant:'__TENANT_ID__' , // o tenanti deve ser enviando no header com o name X-Tenant-ID - ${TENANT_ID}
  GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4'
};
