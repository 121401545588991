import { MenuItem } from "../layout/shared/models/menu.model";

// menu items for vertcal and detached layout
const MENU_ITEMS: MenuItem[] = [
  {
    key: 'home',
    label: 'Home',
    isTitle: false,
    icon: 'mdi mdi-home-outline',
    url: '/home',
  },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-view-dashboard-outline',
    collapsed: true,
    children: [
      // {
      //   key: 'administrador',
      //   label: 'Administrador',
      //   url: '/dashboard/administrador',
      //   icon: 'mdi mdi-circle-medium',
      //   parentKey: 'dashboard',
      // },
      {
        key: 'clube',
        label: 'Clube',
        url: '/dashboard/clube',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'dashboard',
      },
      // {
      //   key: 'faturamento',
      //   label: 'Faturamento',
      //   url: '/dashboard/faturamento',
      //   icon: 'mdi mdi-circle-medium',
      //   parentKey: 'dashboard',
      // }
    ]
  },
  {
    key: 'clubes',
    label: 'Clubes',
    isTitle: false,
    icon: 'mdi mdi-shield-half-full',
    // url: '/clubes',
    collapsed: true,
    children: [
      {
        key: 'clubes',
        label: 'Clube',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/clubes',
      },
      {
        key: 'cupons',
        label: 'Cupons',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/cupons',
        parentKey: 'clubes',
      },
      {
        key: 'parceiros',
        label: 'Parceiros',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/parceiros',
        parentKey: 'clubes',
      },
      {
        key: 'torcida-organizada',
        label: 'Torcida Organizada',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/torcida-organizada',
        parentKey: 'clubes',
      },
      {
        key: 'locais-venda',
        label: 'Locais de Venda',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/locais-venda',
        parentKey: 'clubes',
      },
      {
        key: 'servicos-produtos',
        label: 'Serviços/Produtos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/servicos-produtos',
        parentKey: 'clubes',
      },
      {
        key: 'modulos',
        label: 'Módulos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/modulos',
        parentKey: 'clubes',
      },
      {
        key: 'club-modulos',
        label: 'Módulos x Clubes',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/modulos-clubes',
        parentKey: 'clubes',
      },
    ]
  },
  {
    key: 'planos',
    label: 'Planos',
    isTitle: false,
    icon: 'mdi mdi-account-multiple',
    collapsed: true,
    children: [
      {
        key: 'planos',
        label: 'Planos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/planos',
        parentKey: 'planos',
      },
      {
        key: 'definicoes-beneficios',
        label: 'Benefícios',
        url: '/definicoes/beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'planos',
      },
    ]
  },

  {
    key: 'socios',
    label: 'Sócios',
    isTitle: false,
    icon: 'mdi mdi-handshake',
    collapsed: true,
    children: [
      {
        key: 'page-socios',
        label: 'Sócios',
        url: '/socios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
      },
      {
        key: 'checkin',
        label: 'Checkin',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/checkin',
        parentKey: 'socios',
      },
      {
        key: 'aprovacao-documentos',
        label: 'Aprovação de Documentos',
        url: '/aprovacao-de-documentos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
      },
      {
        key: 'consulta-externa',
        label: 'Consulta Externa',
        icon: 'mdi mdi-circle-medium',
        url: '/socios/consulta-externa',
        parentKey: 'socios',
      },
      {
        key: 'blacklist',
        label: 'Blacklist',
        url: '/socios/blacklist',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'socios',
      },
    ]
  },
  {
    key: 'relatorios',
    label: 'Relatórios',
    isTitle: false,
    icon: 'mdi mdi-file-chart-outline',
    collapsed: true,
    children: [
      {
        key: 'relatorio-mensal-socios',
        label: 'Mensal - Sócio',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-mensal-socios',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-renovacoes',
        label: 'Renovações',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-renovacoes',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-upgrades',
        label: 'Upgrades',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-upgrades',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-dependentes',
        label: 'Dependentes',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-dependentes',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-quadro-resumo',
        label: 'Quadro Resumo',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-quadro-resumo',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-resumo-socios',
        label: 'Resumo de Sócios',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-resumo-socios',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-estorno',
        label: 'Relatório de Estornos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-estornos',
        parentKey: 'relatorios'
      },
      {
        key: 'relatorio-inadimplentes',
        label: 'Relatório de Inadimplêntes',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorios/relatorio-inadimplentes',
        parentKey: 'relatorios'
      },
      {
        key: 'movimentacao-de-status',
        label: 'Relatorio de Status',
        isTitle: false,
        url: '/movimentacao-de-status',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'relatorios',
      },
      {
        key: 'relatorio-de-pagamentos',
        label: 'Relatório de Pagamentos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/relatorio-de-pagamentos',
        parentKey: 'relatorios'
      },
    ]
  },
  {
    key: 'exports',
    label: 'Exportar',
    isTitle: false,
    icon: 'mdi mdi-export',
    collapsed: true,
    children: [
      {
        key: 'export-carteirinhas',
        label: 'Carteirinhas',
        url: '/exports/export-carteirinhas',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'exports',
      },]
  },
  {
    key: 'jogos',
    label: 'Jogos',
    isTitle: false,
    icon: 'mdi mdi-soccer',
    collapsed: true,
    children: [
      {
        key: 'page-jogos',
        label: 'Jogos',
        url: '/jogos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos',
      },
      {
        key: 'adversarios',
        label: 'Adversários',
        url: '/jogos/adversarios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos'
      },
      {
        key: 'campeonatos',
        label: 'Campeonatos',
        url: '/jogos/campeonatos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos'
      },
      {
        key: 'locais',
        label: 'Locais',
        url: '/jogos/locais',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'jogos'
      }
    ]
  },
  {
    key: 'ondas',
    label: 'Ondas',
    isTitle: false,
    icon: 'mdi mdi-waves',
    url: '/ondas',
  },
  {
    key: 'gamificacao',
    label: 'Gamificação',
    isTitle: false,
    icon: 'mdi mdi-gamepad',
    collapsed: true,
    children: [
      {
        key: 'promocoes',
        label: 'Experiências',
        url: '/gamificacao/experiencias',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'gamificacao',
      },
      {
        key: 'acoes-pontuadoras',
        label: 'Ações Pontuadoras',
        url: '/gamificacao/acoes-pontuadoras',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'gamificacao',
      }
    ]
  },
  {
    key: 'mgm',
    label: 'MGM',
    isTitle: false,
    icon: 'mdi mdi-arrow-left-right',
    collapsed: true,
    children: [
      {
        key: 'indicacoes',
        label: 'Relatório de Indicações',
        url: '/mgm/indicacoes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'mgm',
      },
      {
        key: 'socios-indicaram',
        label: 'Sócios que Indicaram',
        url: '/mgm/socios-indicaram',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'mgm',
      },
      {
        key: 'abatimento-pontos',
        label: 'Abatimento de Pontos',
        url: '/mgm/abatimento-pontos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'mgm',
      },
    ]
  },
  {
    key: 'marketing',
    label: 'Marketing',
    isTitle: false,
    icon: 'mdi mdi-email-outline',
    collapsed: true,
    children: [
      {
        key: 'envios-email',
        label: 'E-mails em Massa',
        url: '/marketing/envios-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
      },
      {
        key: 'notificacoes',
        label: 'Notificações',
        url: '/marketing/notificacoes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
      },
      {
        key: 'marketing',
        label: 'Modelos de E-mail',
        url: '/marketing/modelos-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
      },
      {
        key: 'tipos-email',
        label: 'Tipos de E-mail',
        url: '/marketing/tipos-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
      },
      {
        key: 'variaveis-email',
        label: 'Variáveis de E-mail',
        url: '/marketing/variaveis-email',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'marketing',
      },
    ]
  },
  {
    key: 'parametros-consulta-externa',
    label: 'Integrações',
    isTitle: false,
    icon: 'mdi mdi-view-module',
    collapsed: true,
    children: [
      {
        key: 'parametros-consulta-externa',
        label: 'Parâmetros de Consulta Externa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/parametros-consulta-externa',
        parentKey: 'parametros-consulta-externa'
      },
      {
        key: 'integracoes',
        label: 'Integrações',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/integracoes',
      },
    ]
  },
  // {
  //   key: 'account-launch',
  //   label: 'Administrativo',
  //   isTitle: false,
  //   icon: 'mdi mdi-cash-register',
  //   collapsed: true,
  //   children: [
  //     {
  //       key: 'account-launch',
  //       label: 'Lançamento de Contas',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/lancamento-de-contas',
  //       parentKey: 'account-launch'
  //     },
  //     {
  //       key: 'contratos',
  //       label: 'Contratos',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/contratos',
  //       parentKey: 'account-launch'
  //     },
  //     {
  //       key: 'contas',
  //       label: 'Contas',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/contas',
  //       parentKey: 'account-launch'
  //     },
  //     {
  //       key: 'tipos-taxa-contrato',
  //       label: 'Tipos de Taxa Contrato',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/tipos-taxa-contrato',
  //       parentKey: 'account-launch'
  //     },
  //     {
  //       key: 'fechamento-mensal',
  //       label: 'Fechamento Mensal',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/fechamento-mensal',
  //       parentKey: 'account-launch'
  //     },

  //     {
  //       key: 'status-relatorio-fechamentos',
  //       label: 'Status Relatório Fechamento',
  //       isTitle: false,
  //       icon: 'mdi mdi-circle-medium',
  //       url: '/status-relatorio-fechamento',
  //       parentKey: 'account-launch'
  //     },

  //   ]
  // },
  {
    label: 'Configurações',
    isTitle: true
  },
  {
    key: 'configurar-portal',
    label: 'Configurar Portal',
    isTitle: false,
    icon: 'mdi mdi-web',
    collapsed: true,
    children: [
      {
        key: 'geral',
        label: 'Configuração Geral',
        url: '/configurar-portal/geral',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'cabecalho',
        label: 'Cabeçalho',
        url: '/configurar-portal/cabecalho',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'principal',
        label: 'Principal',
        url: '/configurar-portal/principal',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'rodape',
        label: 'Rodapé',
        url: '/configurar-portal/rodape',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'config-planos',
        label: 'Planos',
        url: '/configurar-portal/planos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'beneficios',
        label: 'Benefícios',
        url: '/configurar-portal/beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
            key: 'banner',
            label: 'Banner',
            url: '/configurar-portal/banner',
            icon: 'mdi mdi-circle-medium',
            parentKey: 'configurar-portal',
          },
      {
        key: 'conteudo-exclusivo-config',
        label: 'Conteúdo Exclusivo',
        url: '/configurar-portal/conteudo-exclusivo',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'secao-video',
        label: 'Seção Vídeo',
        url: '/configurar-portal/secao-video',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'tabela-beneficios',
        label: 'Tabela de Benefícios',
        url: '/configurar-portal/tabela-beneficios',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'proximos-confrontos',
        label: 'Próximos Confrontos',
        url: '/configurar-portal/proximos-confrontos',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'tabela-ranking',
        label: 'Tabela de Ranking',
        url: '/configurar-portal/tabela-ranking',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      },
      {
        key: 'faq',
        label: 'FAQ',
        url: '/configurar-portal/faq',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'configurar-portal',
      }
    ]
  },
  {
    key: 'definicoes',
    label: 'Definições',
    isTitle: false,
    icon: 'mdi mdi-tools',
    collapsed: true,
    children: [
      {
        key: 'balances',
        label: 'Balanço',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/balances',
        parentKey: 'definicoes',
      },
      {
        key: 'definicoes-termos',
        label: 'Termos de Uso',
        url: '/definicoes/termos-de-uso',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
      },
      {
        key: 'definicoes-politica-privacidade',
        label: 'Política de Privacidade',
        url: '/definicoes/politica-privacidade',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
      },
      {
        key: 'conteudo-exclusivo',
        label: 'Conteúdo Exclusivo',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/conteudo-exclusivo',
        parentKey: 'definicoes',
      },
      {
        key: 'autor',
        label: 'Autor',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/autor',
        parentKey: 'definicoes',
      },
      {
        key: 'categorias',
        label: 'Categorias',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/categorias',
        parentKey: 'definicoes',
      },
      {
        key: 'rede-social',
        label: 'Rede Social',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/rede-social',
        parentKey: 'definicoes',
      },
      {
        key: 'cargos',
        label: 'Cargos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/cargos',
        parentKey: 'definicoes',
      },
      {
        key: 'departamentos',
        label: 'Departamentos',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/departamentos',
        parentKey: 'definicoes',
      },
      {
        key: 'definicoes-perguntas-frequentes',
        label: 'Perguntas Frequentes',
        url: '/definicoes/faq',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'definicoes',
      }
    ]
  },
  {
    key: 'usuarios',
    label: 'Segurança',
    isTitle: false,
    icon: 'mdi mdi-security',
    collapsed: true,
    children: [
      {
        key: 'usuarios',
        label: 'Usuários',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/usuarios',
        parentKey: 'usuarios'
      },
      {
        key: 'perfis',
        label: 'Perfis',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/perfis',
        parentKey: 'usuarios'
      },
      {
        key: 'funcionalidades',
        label: 'Funcionalidades',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/funcionalidades',
        parentKey: 'usuarios'
      },
      {
        key: 'log-sistema',
        label: 'Log do Sistema',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/log-sistema',
        parentKey: 'usuarios'
      },
    ]
  },
  {
    key: 'tipos-acao',
    label: 'Geral',
    isTitle: false,
    icon: 'mdi mdi-cog',
    collapsed: true,
    children: [
      {
        key: 'tipos-acao-menu',
        label: 'Tipos de Ação Log',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-acao',
        parentKey: 'tipos-acao'
      },
      {
        key: 'tipo-status-pagamento',
        label: 'Tipo Status Pagamento',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipo-status-pagamento',
        parentKey: 'tipos-acao'
      },
      {
        key: 'tipo-de-pagamento',
        label: 'Tipo de Pagamento',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipo-de-pagamento',
        parentKey: 'tipos-acao'
      },
      {
        key: 'tipos-taxa',
        label: 'Tipos de Taxa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-taxa',
        parentKey: 'tipos-acao'
      },
      {
        key: 'valores',
        label: 'Valores Taxa',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/valores-taxa',
        parentKey: 'tipos-acao'
      },
      {
        key: 'tipos-fatura',
        label: 'Tipos de Fatura',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tipos-fatura',
        parentKey: 'tipos-acao'
      },
      {
        key: 'motivos-cancelamento-fatura',
        label: 'Motivos de Cancelamento de Fatura',
        url: '/socios/motivos-cancelamento-fatura',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
      {
        key: 'motivos-estorno-fatura',
        label: 'Motivos de Estorno de Fatura',
        url: '/socios/motivos-estorno-fatura',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
      {
        key: 'origem-socio',
        label: 'Origem Sócio Torcedor',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/origem-socio',
        parentKey: 'tipos-acao',
      },
      {
        key: 'motivos-cadastro',
        label: 'Motivos de Cadastro',
        url: '/socios/motivos-cadastro',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
      {
        key: 'situacoes',
        label: 'Situações',
        url: '/socios/situacoes',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
      {
        key: 'tags',
        label: 'Tags',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/tags',
        parentKey: 'tipos-acao',
      },
      {
        key: 'assuntos-crm',
        label: 'Assuntos CRM',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/assuntos-crm',
        parentKey: 'tipos-acao',
      },
      {
        key: 'classificacao-socio',
        label: 'Classificação Sócio Torcedor',
        isTitle: false,
        icon: 'mdi mdi-circle-medium',
        url: '/classificacao-socio',
        parentKey: 'tipos-acao',
      },
      {
        key: 'tipos-plano',
        label: 'Tipos de Plano',
        url: '/tipos-plano',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
      {
        key: 'status-plano',
        label: 'Status do Plano',
        icon: 'mdi mdi-circle-medium',
        url: '/status-plano',
        parentKey: 'tipos-acao',
      },
      {
        key: 'motivos-cancelamento',
        label: 'Motivos de Cancelamento',
        url: '/motivos-cancelamento',
        icon: 'mdi mdi-circle-medium',
        parentKey: 'tipos-acao',
      },
    ]
  },





  // {
  //     key: 'forms',
  //     label: 'forms',
  //     isTitle: false,
  //     icon: 'mdi mdi-handshake',
  //     url: '/forms',
  // }



];

const HORIZONTAL_MENU_ITEMS: MenuItem[] = [];

export { MENU_ITEMS, HORIZONTAL_MENU_ITEMS };
