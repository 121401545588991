import { Directive, ElementRef, HostListener, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import * as StringMask from 'string-mask';

@Directive({
  selector: '[moneyMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyDirective),
      multi: true
    }
  ]
})
export class MoneyDirective implements OnInit, ControlValueAccessor {

  private decimalDelimiter = ',';
  private thousandsDelimiter = '.';
  private decimals = 2;

  private decimalsPattern = this.decimals > 0 ? this.decimalDelimiter + new Array(this.decimals + 1).join('0') : '';
  private maskPattern = '#' + this.thousandsDelimiter + '##0' + this.decimalsPattern;

  private moneyMask = new StringMask(this.maskPattern, { reverse: true });

  public onChangeCallback = (_: any) => {};
  @HostListener('blur', ['$event'])
  public onTouchCallback = () => {};
  validateFn: any = () => {};

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    const cleanValue: string = this._cleanValue(this._elementRef.nativeElement.value);
    this._applyValueChanges(cleanValue);
  }

  @HostListener('input')
  onKeydow(): void {
    const cleanValue: string = this._cleanValue(this._elementRef.nativeElement.value);
    this._applyValueChanges(cleanValue);
  }

  public writeValue(inputValue: string): void {
    if (!inputValue) {
      this._elementRef.nativeElement.value = null;
    } else if (inputValue && !isNaN(Number(inputValue))) {
      const value = parseFloat(inputValue).toFixed(this.decimals).replace('.', this.decimalDelimiter);
      this._elementRef.nativeElement.value = value;
    } else {
      this._elementRef.nativeElement.value = inputValue;
    }
    const cleanValue: string = this._cleanValue(this._elementRef.nativeElement.value);
    this._applyValueChanges(cleanValue);
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    return;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  private _applyValueChanges(cleanValue): void {
    if (cleanValue) {
      const formattedValue = this.moneyMask.apply(cleanValue);
      this._elementRef.nativeElement.value = formattedValue;
      this.onChangeCallback(
        formattedValue ? parseInt(formattedValue.replace(/[^\d]+/g, ''), 10) / Math.pow(10, this.decimals) : null
      );
    }
  }

  private _cleanValue(viewValue): string {
    let cleanValue = viewValue.toString().replace(/[^\d]+/g, '');
    cleanValue = cleanValue.replace(/^[0]+([1-9])/, '$1');
    return cleanValue;
  }
}
